var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.loading)?_c('VContainer',{staticClass:"account-login",attrs:{"fluid":""}},[_c('VForm',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('header',[_c('img',{staticClass:"logo",attrs:{"src":(_vm.vendor.branding && _vm.vendor.branding.logo) ? `/${_vm.vendor.branding.logo}` : _vm.logotype,"alt":_vm.vendor.title,"aria-hidden":"true"}}),_c('h1',{staticClass:"page-title"},[_vm._v(" "+_vm._s(_vm.localDocumentTitle)+" ")])]),_c('VTextField',{attrs:{"label":_vm.$t('usernames.one'),"placeholder":_vm.$t('usernames.one'),"persistent-placeholder":"","outlined":"","autofocus":"","name":"username","rules":[
        v => !!(v || '').trim().length || 'Это поле обязательно',
        v => (v || '').trim().length <= 254 || 'Логин должен быть не больше 254 символов'
      ]},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('VTextField',{attrs:{"label":_vm.$t('passwords.one'),"placeholder":_vm.$t('passwords.one'),"persistent-placeholder":"","name":"password","type":_vm.isPasswordVisible ? 'text' : 'password',"outlined":"","rules":[
        v => !!(v || '').trim().length || 'Это поле обязательно',
        v => (v || '').trim().length >= 6 || 'Пароль должен быть не меньше 6 символов',
        v => (v || '').trim().length <= 254 || 'Пароль должен быть не больше 254 символов'
      ],"append-icon":_vm.isPasswordVisible ? 'visibility_off' : 'visibility'},on:{"click:append":function($event){_vm.isPasswordVisible = !_vm.isPasswordVisible}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),(_vm.vendor.branding && _vm.vendor.branding.show_privacy_policy_approval && _vm.vendor.branding.privacy_policy_approval)?_c('VCheckbox',{staticClass:"privacy-policy-checkbox",attrs:{"name":"privacy_policy_accepted","value":"1","rules":[v => !!v || 'Чтобы продолжить, установите этот флажок'],"required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"privacy-policy-title rte-content",domProps:{"innerHTML":_vm._s(_vm.vendor.branding.privacy_policy_approval)},on:{"click":function($event){$event.stopPropagation();}}})]},proxy:true}],null,false,81501866)}):_vm._e(),_c('VBtn',{attrs:{"x-large":"","type":"submit","block":"","color":"primary","loading":_vm.isSubmitting}},[_vm._v(" "+_vm._s(_vm.$t('anonymous_sign_in.one'))+" ")]),_c('footer',{staticClass:"text-center"},[_c('VCardText',[(_vm.vendor.branding.self_registration_enabled)?_c('p',[_vm._v(" "+_vm._s(_vm.$t('anonymous_first_question.one'))+" "),_c('RouterLink',{attrs:{"to":"/register"}},[_vm._v(" "+_vm._s(_vm.$t('anonymous_register.one'))+" ")])],1):_vm._e(),_c('RouterLink',{attrs:{"to":"/reset"}},[_vm._v(" "+_vm._s(_vm.$t('anonymous_forgot_password.one'))+" ")])],1),(_vm.vendor.branding.show_guest_online_shop_link)?_c('VBtn',{attrs:{"href":"/shop","large":"","block":"","outlined":"","color":"success"}},[_vm._v(" "+_vm._s(_vm.$t('online_shop.one'))+" ")]):_vm._e(),_c('VDivider'),_c('VCardText',[_c('a',{attrs:{"href":"/privacy-policy","target":"_blank","rel":"noopener noreferrer"}},[_vm._v(_vm._s(_vm.$t('personal_data_processing_policy.one')))])])],1)],1)],1):_c('VProgressCircular',{staticClass:"app-loader",attrs:{"size":50,"color":"primary","indeterminate":""}})
}
var staticRenderFns = []

export { render, staticRenderFns }